import { useNavigate } from "react-router-dom";
import React, { createContext, useState, useEffect, useContext } from "react";

const InactivityContext = createContext();

export const useInactivity = () => useContext(InactivityContext);

export const InactivityProvider = ({ children, timeout = 900000 }) => {
  const [isInactive, setIsInactive] = useState(false);
  const navigate = useNavigate();
  let inactivityTimer;

  const resetInactivityTimer = () => {
    clearTimeout(inactivityTimer);
    setIsInactive(false);
    inactivityTimer = setTimeout(() => {
      setIsInactive(true);
      navigate("/login");
    }, timeout);
  };

  useEffect(() => {
    const events = ["mousemove", "keydown", "mousedown", "touchstart"];

    events.forEach((event) => {
      window.addEventListener(event, resetInactivityTimer);
    });

    resetInactivityTimer();

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetInactivityTimer);
      });
      clearTimeout(inactivityTimer);
    };
  }, [timeout, navigate]);

  return (
    <InactivityContext.Provider value={{ isInactive }}>
      {children}
    </InactivityContext.Provider>
  );
};
