import React, { useState, useEffect, useCallback, useRef } from "react";
import { List, arrayMove } from "react-movable";
import {
  PlusCircle,
  Search,
  Trash2,
  Edit2,
  GripVertical,
  Link,
} from "lucide-react";
import TemplatePage from "../template/template";
import { GlobalApi } from "../../global";
import { useNavigate, useLocation } from "react-router-dom";

const components = [
  "DocTitle",
  "Title",
  "SubTitle",
  "Content",
  "Console",
  "PromptInfo",
];

const consoleTypes = [
  "bash",
  "fish",
  "yaml",
  "shell",
  "javascript",
  "python",
  "json",
  "console",
  "conf",
];

const UpdateDocGenerator = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageName, setPageName] = useState("");
  const [selectedComponent, setSelectedComponent] = useState("");
  const [componentContent, setComponentContent] = useState("");
  const [consoleType, setConsoleType] = useState("");
  const [editingIndex, setEditingIndex] = useState(null);
  const [showLinkInput, setShowLinkInput] = useState(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [linkText, setLinkText] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const docName = location.pathname.split("/")[3];
  const [isListReady, setIsListReady] = useState(false);

  // New state for tags
  const tagRef = useRef(null);
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [filteredTags, setFilteredTags] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [data, setData] = useState([]);
  const [numTabs, setNumTabs] = useState(1);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (tagRef.current && !tagRef.current.contains(e.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);
  useEffect(() => {
    const getTags = async () => {
      try {
        setLoading(true);
        setError("");
        const response = await fetch(`${GlobalApi()}/api/docs/tags`, {
          method: "GET",
          credentials: "include",
        });
        const data = await response.json();
        if (data.status === "error") {
          if (data.type === "jwt") {
            navigate("/login");
          }
          setError(data.error);
          setLoading(false);
          return;
        } else if (data.status === "ok") {
          setData(data.tags || []);
          setFilteredTags(data.tags || []);
        }
      } catch (e) {
        setError(e.message);
      }
      setLoading(false);
    };
    getTags();
  }, []);

  useEffect(() => {
    if (!tagInput) {
      setFilteredTags(data);
    } else {
      const filtered = data.filter((tag) =>
        tag.Name.toLowerCase().includes(tagInput.toLowerCase()),
      );
      setFilteredTags(filtered);
    }
  }, [tagInput, data]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError("");
        const response = await fetch(
          `${GlobalApi()}/api/docs/json/${docName}`,
          {
            method: "GET",
            credentials: "include",
          },
        );
        const data = await response.json();
        if (data.status === "error") {
          if (data.type === "jwt") {
            navigate("/login");
          }
          setError(data.error);
          return;
        } else if (data.status === "ok") {
          setPageName(data.doc.pageName);
          setDescription(data.doc.description);
          setItems(
            data.doc.components.map((component, index) => ({
              ...component,
              id: `item-${index}`,
            })),
          );
          setTags(data.doc.tags || []);
          setIsListReady(true);
        }
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [docName, navigate]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError("");
      const response = await fetch(
        `${GlobalApi()}/api/docs/update/${docName}`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            pageName,
            description,
            components: items,
            tags,
          }),
        },
      );
      const data = await response.json();
      if (data.status === "error") {
        if (data.type === "jwt") {
          navigate("/login");
        }
        setError(data.error);
        return;
      } else if (data.status === "ok") {
        navigate("/docs");
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTagSelect = (selectedTag) => {
    if (!tags.some((tag) => tag.Name === selectedTag.Name)) {
      setTags([...tags, selectedTag]);
    }
    setTagInput("");
    setIsDropdownOpen(false);
  };

  const removeTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag.Name !== tagToRemove.Name));
  };

  const addTag = () => {
    if (
      tagInput &&
      !tags.some((tag) => tag.Name.toLowerCase() === tagInput.toLowerCase())
    ) {
      const newTag = {
        TagID: numTabs,
        Name: tagInput,
      };
      setNumTabs(numTabs + 1);
      setTags([...tags, newTag]);
      setTagInput("");
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this document?")) {
      try {
        setLoading(true);
        setError("");
        const response = await fetch(
          `${GlobalApi()}/api/docs/delete/${docName}`,
          {
            method: "DELETE",
            credentials: "include",
          },
        );
        const data = await response.json();
        if (data.status === "error") {
          if (data.type === "jwt") {
            navigate("/login");
          }
          setError(data.error);
          return;
        } else if (data.status === "ok") {
          navigate("/docs");
        }
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const addItem = useCallback(() => {
    if (selectedComponent && componentContent) {
      const newItem = {
        id: `item-${items.length}`,
        type: selectedComponent,
        content: componentContent,
      };
      if (selectedComponent === "Console") {
        newItem.consoleType = consoleType;
      }
      setItems((prevItems) => [...prevItems, newItem]);
      setSelectedComponent("");
      setComponentContent("");
      setConsoleType("");
    }
  }, [selectedComponent, componentContent, consoleType, items.length]);

  const removeItem = useCallback((index) => {
    setItems((prevItems) => prevItems.filter((_, i) => i !== index));
  }, []);

  const editItem = useCallback(
    (index) => {
      setEditingIndex(index);
      setSelectedComponent(items[index].type);
      setComponentContent(items[index].content);
      if (items[index].type === "Console") {
        setConsoleType(items[index].consoleType || "");
      } else {
        setConsoleType("");
      }
    },
    [items],
  );

  const updateItem = useCallback(() => {
    if (editingIndex !== null && selectedComponent && componentContent) {
      setItems((prevItems) => {
        const newItems = [...prevItems];
        const updatedItem = {
          ...newItems[editingIndex],
          type: selectedComponent,
          content: componentContent,
        };
        if (selectedComponent === "Console") {
          updatedItem.consoleType = consoleType;
        }
        newItems[editingIndex] = updatedItem;
        return newItems;
      });
      setEditingIndex(null);
      setSelectedComponent("");
      setComponentContent("");
      setConsoleType("");
    }
  }, [editingIndex, selectedComponent, componentContent, consoleType]);

  const insertLink = () => {
    const newContent =
      componentContent + `<Ref href="${linkUrl}">${linkText}</Ref>`;
    setComponentContent(newContent);
    setShowLinkInput(false);
    setLinkUrl("");
    setLinkText("");
  };

  const renderContent = (content) => {
    const parts = content.split(/(<Ref.*?<\/Ref>)/);
    return parts.map((part, index) => {
      if (part.startsWith("<Ref")) {
        const hrefMatch = part.match(/href="(.*?)"/);
        const textMatch = part.match(/>(.+?)</);
        if (hrefMatch && textMatch) {
          return (
            <a
              key={index}
              href={hrefMatch[1]}
              className="text-primary hover:underline"
            >
              {textMatch[1]}
            </a>
          );
        }
      }
      return part;
    });
  };

  return (
    <TemplatePage loading={loading} setLoading={setLoading}>
      <div className="p-4 flex flex-1 flex-col overflow-auto scrollbar">
        <h1 className="text-2xl font-bold mb-4 text-gray-400">
          Update Documentation
        </h1>
        {error && <p className="text-red-500 mb-4">{error}</p>}

        <div className="mb-4">
          <label htmlFor="pageName" className="block text-gray-400 mb-2">
            Page Name (for URL):
          </label>
          <input
            type="text"
            id="pageName"
            value={pageName}
            onChange={(e) => setPageName(e.target.value)}
            className="w-full p-2 border border-primary rounded bg-chat text-white mb-4"
            placeholder="Enter page name"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="description" className="block text-gray-400 mb-2">
            Description:
          </label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 border border-primary rounded bg-chat text-white mb-4"
            placeholder="Enter description"
          />
        </div>
        {/* Add tag management section */}
        <div ref={tagRef} id="tags" className="mb-4 relative">
          <label htmlFor="tags" className="block text-gray-400 mb-2">
            Tags:
          </label>
          <div className="relative mb-2 flex items-center justify-center gap-2">
            <input
              type="text"
              className="w-full p-2 pr-10 border border-primary rounded bg-chat text-white"
              placeholder="Add tags"
              value={tagInput}
              onChange={(e) => {
                setTagInput(e.target.value);
                setIsDropdownOpen(true);
              }}
              onFocus={() => setIsDropdownOpen(true)}
            />
            <button
              type="button"
              onClick={addTag}
              className="bg-primary text-white p-2 rounded flex items-center hover:bg-primary/70 transition-colors"
            >
              Add
            </button>
            <Search
              className="absolute right-24 top-1/2 transform -translate-y-1/2 text-gray-400"
              size={20}
            />
          </div>
          {isDropdownOpen && filteredTags.length > 0 && (
            <ul className="absolute w-full mt-1 max-h-60 w-56 overflow-auto scrollbar rounded bg-base z-10 border border-primary rounded bg-chat text-white z-10">
              {filteredTags.map((tag) => (
                <li
                  key={tag.TagID}
                  className="p-2 hover:bg-primary cursor-pointer"
                  onClick={() => handleTagSelect(tag)}
                >
                  {tag.Name}
                </li>
              ))}
            </ul>
          )}
        </div>
        {tags.length > 0 && (
          <ul
            id="tag-list"
            className="border border-primary rounded-lg p-4 flex items-center flex-wrap w-full mb-4"
          >
            {tags.map((tag, index) => (
              <li
                key={index}
                className="mr-2 bg-primary p-2 text-white rounded-lg flex gap-2"
              >
                {tag.Name}
                <button onClick={() => removeTag(tag)}>
                  <Trash2 />
                </button>
              </li>
            ))}
          </ul>
        )}

        <div className="mb-4 space-y-2">
          <select
            className="w-full p-2 border border-primary rounded bg-chat text-white"
            value={selectedComponent}
            onChange={(e) => setSelectedComponent(e.target.value)}
          >
            <option value="">Select a component</option>
            {components.map((comp) => (
              <option key={comp} value={comp}>
                {comp}
              </option>
            ))}
          </select>
          {selectedComponent === "Console" && (
            <select
              className="w-full p-2 border border-primary rounded bg-chat text-white"
              value={consoleType}
              onChange={(e) => setConsoleType(e.target.value)}
            >
              <option value="">Select console type</option>
              {consoleTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          )}
          <div className="relative">
            <textarea
              className="w-full p-2 border border-primary rounded bg-chat text-white"
              value={componentContent}
              onChange={(e) => setComponentContent(e.target.value)}
              placeholder="Enter component content"
              rows="3"
            />
            <button
              onClick={() => setShowLinkInput(!showLinkInput)}
              className="absolute top-2 right-2 text-primary"
            >
              <Link size={16} />
            </button>
          </div>
          {showLinkInput && (
            <div className="space-y-2">
              <input
                type="text"
                value={linkUrl}
                onChange={(e) => setLinkUrl(e.target.value)}
                placeholder="Enter link URL"
                className="w-full p-2 border border-primary rounded bg-chat text-white"
              />
              <input
                type="text"
                value={linkText}
                onChange={(e) => setLinkText(e.target.value)}
                placeholder="Enter link text"
                className="w-full p-2 border border-primary rounded bg-chat text-white"
              />
              <button
                onClick={insertLink}
                className="bg-primary text-white p-2 rounded"
              >
                Insert Link
              </button>
            </div>
          )}
          <button
            className="bg-primary text-white p-2 rounded flex items-center hover:bg-primary/70 transition-colors"
            onClick={editingIndex !== null ? updateItem : addItem}
          >
            <PlusCircle className="mr-2" size={16} />
            {editingIndex !== null ? "Update" : "Add"} Component
          </button>
        </div>
        {isListReady && (
          <List
            values={items}
            onChange={({ oldIndex, newIndex }) =>
              setItems(arrayMove(items, oldIndex, newIndex))
            }
            renderList={({ children, props }) => <ul {...props}>{children}</ul>}
            renderItem={({ value, index, props }) => (
              <li
                {...props}
                className="flex items-center justify-between p-2 bg-base text-white rounded mb-2"
              >
                <div className="flex items-center w-full">
                  <span className="mr-2 w-4 text-primary cursor-grab">
                    <GripVertical size={16} />
                  </span>
                  <span>
                    <p className="text-primary">{value.type}:</p>
                    {value.consoleType ? ` (${value.consoleType})` : ""}{" "}
                    {renderContent(value.content)}
                  </span>
                </div>
                <div className="w-12">
                  <button
                    onClick={() => editItem(index)}
                    className="text-primary mr-2"
                  >
                    <Edit2 size={16} />
                  </button>
                  <button
                    onClick={() => removeItem(index)}
                    className="text-red-500"
                  >
                    <Trash2 size={16} />
                  </button>
                </div>
              </li>
            )}
          />
        )}

        <div className="flex justify-between mt-4">
          <button
            type="button"
            onClick={handleSubmit}
            className="bg-primary text-white p-2 rounded flex items-center hover:bg-primary/70 transition-colors"
          >
            Update Document
          </button>
          <button
            type="button"
            onClick={handleDelete}
            className="bg-red-500 text-white p-2 rounded flex items-center hover:bg-red-600 transition-colors"
          >
            Delete Document
          </button>
        </div>

        <div className="mt-4">
          <h2 className="text-xl text-gray-400 font-semibold mb-2">
            Generated JSON:
          </h2>
          <pre className="bg-base text-white p-2 rounded w-full overflow-x-auto scrollbar">
            {JSON.stringify(
              { pageName, description, tags, components: items },
              null,
              2,
            )}
          </pre>
        </div>
      </div>
    </TemplatePage>
  );
};

export default UpdateDocGenerator;
