import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Login from "./pages/login";
import Register from "./pages/register";
import CreateVault from "./pages/vault/create";
import TeamsAdmin from "./pages/team/teams";
import TeamsAdminNew from "./pages/team/new";
import Edit from "./pages/team/edit";
import ManageVault from "./pages/vault/manage";
import ViewsVaultTeams from "./pages/vault_teams/Views_teams";
import CreateVaultTeams from "./pages/vault_teams/create_teams";
import ManageVaultTeams from "./pages/vault_teams/manage_teams";
import Useradmin from "./pages/admin/user";
import Settings from "./pages/admin/settings";
import NewSecret from "./pages/admin/createSecret";
import UserFolder from "./pages/admin/folders";
import NewFolder from "./pages/admin/createFolder";
import Vaults from "./pages/vault/vaults";
import VauldEdit from "./pages/vault/edit";
import VaultView from "./pages/vault/Views";
import ResetPassword from "./pages/reset";
import ResetPasswordToken from "./pages/resetPassword";
import Api from "./pages/admin/api";
import NewApi from "./pages/admin/newApi";
import NewSecretCustom from "./pages/admin/createsecretCustom";
import UpdateCloud from "./pages/cloud/upload";
import Cloud from "./pages/cloud/storage";
import Log from "./pages/admin/log";
import { NavigationProvider } from "./Redux/redux";
import TwoFa from "./pages/twofa/twofa";
import DocumentListPage from "./pages/Docs/home";
import DocGenerator from "./pages/Docs/genDoc";
import DynamicDocRender from "./pages/Docs/dynamicRender";
import UpdateDocGenerator from "./pages/Docs/update";
import PdfView from "./components/Docs/PdfView";
import { InactivityProvider } from "./Provider/activity";

const ProtectedRoute = ({ children }) => (
  <InactivityProvider>{children}</InactivityProvider>
);

function App() {
  return (
    <BrowserRouter>
      <div id="llm-app" className="bg-base min-h-screen w-screen">
        <div className="absolute z-20 top-0 inset-x-0 flex justify-center overflow-hidden pointer-events-none">
          <div className="w-[108rem] flex-none flex justify-end">
            <picture>
              <source srcSet="/effect.avif" type="image/avif" />
              <img
                src="/effect.avif"
                alt=""
                className="w-[71.75rem] flex-none max-w-none opacity-40"
                decoding="async"
              />
            </picture>
          </div>
        </div>
        <NavigationProvider>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/log"
              element={
                <ProtectedRoute>
                  <Log />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/api"
              element={
                <ProtectedRoute>
                  <Api />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/api/new"
              element={
                <ProtectedRoute>
                  <NewApi />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/secrets"
              element={
                <ProtectedRoute>
                  <Useradmin />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/secrets/new"
              element={
                <ProtectedRoute>
                  <NewSecret />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/teams"
              element={
                <ProtectedRoute>
                  <TeamsAdmin />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/secrets/new_custom"
              element={
                <ProtectedRoute>
                  <NewSecretCustom />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/folders"
              element={
                <ProtectedRoute>
                  <UserFolder />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/folder/new"
              element={
                <ProtectedRoute>
                  <NewFolder />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/settings"
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/teams/edit/:id"
              element={
                <ProtectedRoute>
                  <Edit />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/teams/new"
              element={
                <ProtectedRoute>
                  <TeamsAdminNew />
                </ProtectedRoute>
              }
            />
            {/* Docs */}
            <Route
              path="/docs"
              element={
                <ProtectedRoute>
                  <DocumentListPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/docs/new"
              element={
                <ProtectedRoute>
                  <DocGenerator />
                </ProtectedRoute>
              }
            />
            <Route
              path="/docs/:docname"
              element={
                <ProtectedRoute>
                  <DynamicDocRender />
                </ProtectedRoute>
              }
            />
            <Route
              path="/docs/update/:docname"
              element={<UpdateDocGenerator />}
            />
            <Route
              path="/twofa"
              element={
                <ProtectedRoute>
                  <TwoFa />
                </ProtectedRoute>
              }
            />
            <Route
              path="/cloud"
              element={
                <ProtectedRoute>
                  <Cloud />
                </ProtectedRoute>
              }
            />
            <Route
              path="/cloud/uploads"
              element={
                <ProtectedRoute>
                  <UpdateCloud />
                </ProtectedRoute>
              }
            />
            <Route
              path="/cloud/pdf/:folder/:filename"
              element={
                <ProtectedRoute>
                  <PdfView />
                </ProtectedRoute>
              }
            />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/vaults"
              element={
                <ProtectedRoute>
                  <Vaults />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vaults/create"
              element={
                <ProtectedRoute>
                  <CreateVault />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vaults/edit/:id/:folder"
              element={
                <ProtectedRoute>
                  <VauldEdit />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vaults/view/:id/:folder"
              element={
                <ProtectedRoute>
                  <VaultView />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vaults/manage"
              element={
                <ProtectedRoute>
                  <ManageVault />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vaults_teams/create"
              element={
                <ProtectedRoute>
                  <CreateVaultTeams />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vaults_teams/views"
              element={
                <ProtectedRoute>
                  <ViewsVaultTeams />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vaults_teams/manage"
              element={
                <ProtectedRoute>
                  <ManageVaultTeams />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reset"
              element={
                <ProtectedRoute>
                  <ResetPassword />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reset-password/:id"
              element={<ResetPasswordToken />}
            />
            <Route
              path="*"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
          </Routes>
        </NavigationProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;
