import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Search, Edit2, Trash2 } from "lucide-react";
import Pagination from "../paggination";
import { GlobalApi } from "../../global";
import Loading from "../loading";

const DocumentList = ({
  documents,
  currentPage,
  totalPages,
  onPageChange,
  setCurrentPage,
  setItemsPerPage,
  itemsPerPage,
  searchTerm,
  setSearchTerm,
  isLoading,
  tags,
  setTags,
}) => {
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [filteredTags, setFilteredTags] = React.useState([]);
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const tagRef = React.useRef(null);
  const [tagInput, setTagInput] = React.useState("");
  const [numTabs, setNumTabs] = React.useState(0);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };
  useEffect(() => {
    const getTags = async () => {
      try {
        setLoading(true);
        setError("");
        const response = await fetch(`${GlobalApi()}/api/docs/tags`, {
          method: "GET",
          credentials: "include",
        });
        const data = await response.json();
        if (data.status === "error") {
          if (data.type === "jwt") {
            navigate("/login");
          }
          setError(data.error);
          setLoading(false);
          return;
        } else if (data.status === "ok") {
          setData(data.tags || []);
          setFilteredTags(data.tags || []);
        }
      } catch (e) {
        setError(e.message);
      }
      setLoading(false);
    };
    getTags();
  }, []);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (tagRef.current && !tagRef.current.contains(e.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  const handleTagSelect = (selectedTag) => {
    if (!tags.some((tag) => tag.Name === selectedTag.Name)) {
      setTags([...tags, selectedTag]);
    }
    setTagInput("");
    setIsDropdownOpen(false);
  };

  const removeTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag.TagID !== tagToRemove.TagID));
  };

  const addTag = () => {
    if (
      tagInput &&
      !tags.some((tag) => tag.Name.toLowerCase() === tagInput.toLowerCase())
    ) {
      const newTag = {
        TagID: numTabs,
        Name: tagInput,
      };
      setNumTabs(numTabs + 1);
      setTags([...tags, newTag]);
      setTagInput("");
    }
  };

  return (
    <div className="flex flex-1 flex-col w-full h-full gap-4 pb-16">
      {loading && <Loading />}
      {error && <p className="text-red-500">{error}</p>}
      <div className="flex items-center justify-between top-0 z-10 p-4">
        <div className="relative w-full">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
          <input
            type="text"
            placeholder="Search documents..."
            value={searchTerm}
            onChange={handleSearch}
            className="w-full pl-10 pr-4 bg-gray-[#121C22] text-gray-400 h-8 p-1 border rounded-md border-primary bg-chat focus:ring focus:outline-none"
          />
        </div>
        <button
          type="button"
          onClick={() => navigate("/docs/new")}
          className="flex py-1 px-2 items-center gap-2 btn text-gray-300 bg-primary w-68 ml-4 rounded-md"
        >
          New
        </button>
      </div>
      <div ref={tagRef} id="tags" className="mb-4 relative">
        <label htmlFor="tags" className="block text-gray-400 mb-2">
          Tags:
        </label>
        <div className="relative mb-2 flex items-center justify-center gap-2">
          <input
            type="text"
            className="w-full p-2 pr-10 border border-primary rounded bg-chat text-white"
            placeholder="Add tags"
            value={tagInput}
            onChange={(e) => {
              setTagInput(e.target.value);
              setIsDropdownOpen(true);
            }}
            onFocus={() => setIsDropdownOpen(true)}
          />
          <button
            type="button"
            onClick={addTag}
            className="bg-primary text-white p-2 rounded flex items-center hover:bg-primary/70 transition-colors"
          >
            Add
          </button>
          <Search
            className="absolute right-24 top-1/2 transform -translate-y-1/2 text-gray-400"
            size={20}
          />
        </div>
        {isDropdownOpen && filteredTags.length > 0 && (
          <ul className="absolute w-full mt-1 max-h-60 min-h-full w-56 overflow-auto scrollbar rounded bg-base z-10 border border-primary rounded bg-chat text-white z-10">
            {filteredTags.map((tag) => (
              <li
                key={tag.TagID}
                className="p-2 hover:bg-primary cursor-pointer"
                onClick={() => handleTagSelect(tag)}
              >
                {tag.Name}
              </li>
            ))}
          </ul>
        )}
      </div>
      {tags.length > 0 && (
        <ul
          id="tag-list"
          className="border border-primary rounded-lg p-4 flex items-center flex-wrap w-full"
        >
          {tags.map((tag, index) => (
            <li
              key={index}
              className="mr-2 bg-primary p-2 text-white rounded-lg flex gap-2"
            >
              {tag.Name}
              <button onClick={() => removeTag(tag)}>
                <Trash2 />
              </button>
            </li>
          ))}
        </ul>
      )}

      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <p className="text-gray-300">Loading...</p>
        </div>
      ) : (
        <div className="flex min-h-24 overflow-y-scroll scrollbar flex-col gap-4">
          {documents.map((doc, index) => (
            <div key={index} className="bg-base shadow rounded-lg p-4">
              <div className="flex items-center justify-between">
                <a
                  href={`/docs/${doc.Name}`}
                  className="flex gap-2 items-start flex-col"
                >
                  <h2 className="text-xl font-semibold mb-2 text-primary">
                    {doc.Name}
                  </h2>
                  <p className="text-gray-300">{doc.Description}</p>
                </a>
                <button
                  className="text-primary"
                  onClick={() => navigate(`/docs/update/${doc.Name}`)}
                >
                  <Edit2 size={16} />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
      <Pagination
        currentPage={currentPage}
        onPageChange={onPageChange}
        setItemsPerPage={setItemsPerPage}
        totalPages={totalPages}
        itemsPerPage={itemsPerPage}
      />
    </div>
  );
};

export default DocumentList;
